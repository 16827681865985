










































import { Vue, Component, Prop, Watch, PropSync } from "vue-property-decorator";

@Component
export default class DateRange extends Vue {
  menuDate: boolean = false;
  datesRange: string[] = [];
  @Prop({ type: Array, default: () => [] }) readonly value!: string[];
  @Prop({ default: "date" }) readonly label!: string;
  @Prop() readonly hint!: string;

  get dates() {
    return this.value;
  }

  set dates(value) {
    this.$emit("input", value);
  }

  get dateFormatted() {
    return this.$_.isEmpty(this.dates)
      ? []
      : this.$_.chain(this.dates)
          .map(date => this.$moment(date).format("MMMM Do YYYY"))
          .join(" - ")
          .value();
  }
}
